<template>
    <div class="payoutCreate">

        <Card addclass="mt-30">

            <h2>{{ $lng.__('lk_webmaster_user_settings', 'Adding a payment system') }}</h2>

            <div class="payoutCreate__tabs">
                <div
                    v-for="paymentSystem in paymentSystems"
                    class="payoutCreate__tabs__item"
                    @click="selectPaymentSystemTab(paymentSystem)"
                    :class="{active: paymentSystem.id === paymentSystemActiveId}">
                    {{ $lng.__('lk_webmaster_user_settings', paymentSystem.name) }}
                </div>
            </div>

            <div class="payoutCreate__tabContent">

                <div v-if="isCurrentPaymentSystemAlreadyAdded">
                    {{ $lng.__('lk_webmaster_user_settings', 'Please contact your manager to change your payment system details') }}

                </div>

                <Loading v-if="!paymentSystemFields"></Loading>

                <div
                    v-if="!isCurrentPaymentSystemAlreadyAdded"
                    v-for="field in formFields"
                    class="mt-40">

                    <template v-if="field.id === 'network'">
                        <FormSelect
                            :label="$lng.__('lk_webmaster_user_settings', field.label)"
                            class="mb-20"
                            :showResetOption="false"
                            :withIcons="false"
                            v-model:selected="field.value"
                            :options="[{id:'TRC20',name:'TRC20'},{id:'ERC20',name:'ERC20'}]"
                        ></FormSelect>
                    </template>

                    <template v-else>
                        <FormInput
                            :label="$lng.__('lk_webmaster_user_settings', field.label)"
                            placeholder=""
                            v-model="field.value"
                            :validations="[
                                { rule: $validations.isStringNotEmpty(field.value), message: 'Field required'},
                                { rule: $validations.isStringLengthSmallThan(field.value, 255), message: 'Must be small than 255 symbols' },
                            ]"
                        ></FormInput>
                    </template>

                </div>

                <div
                    v-if="isCurrentPaymentSystemAlreadyAdded"
                    v-for="field in formFields"
                    class="mt-40">
                    <FormInput
                        :label="$lng.__('lk_webmaster_user_settings', field.label)"
                        placeholder=""
                        :disabled="true"
                        v-model="field.current_value"
                    ></FormInput>
                </div>

                <button
                    v-if="isFormFieldsValid && !isCurrentPaymentSystemAlreadyAdded"
                    @click="addPaymentSystem"
                    class="mt-30 wv-btn--green wv-btn--text">
                    {{ $lng.__('lk_webmaster_user_settings', 'Add') }}
                </button>

            </div>

        </Card>
    </div>
</template>

<script>
export default {
    name: 'SettingsTabPayoutCreate',
    props: [],
    data() {
        return {
            paymentSystemActiveId: '1',
            formFields: []
        };
    },
    computed: {
        user() {
            return this.$store.state.auth.user;
        },
        paymentSystemActive() {
            return collect(this.paymentSystems)
                .where('id', this.paymentSystemActiveId)
                .first();
        },
        paymentSystems() {
            return this.$store.state.webmaster.paymentSystems;
        },
        paymentSystemFields() {
            return this.$store.state.webmaster.paymentSystem;
        },
        isCurrentPaymentSystemAlreadyAdded() {
            return collect(this.user.payment_systems)
                .where('id', this.paymentSystemActiveId)
                .count() > 0;
        },
        isFormFieldsValid() {
            let pass = true;
            this.formFields.forEach(field => {
                pass = pass
                    ? (this.$validations.isStringNotEmpty(field.value) && this.$validations.isStringLengthSmallThan(field.value, 255))
                    : false;
            });
            return pass;
        },
        preparedFormForRequest() {
            return collect(this.formFields)
                .map(field => {
                    return {key: field.id, value: field.value};
                })
                .all();
        },
    },
    methods: {
        selectPaymentSystemTab(paymentSystem) {
            this.formFields = [];
            this.$store.commit('webmaster/set', ['paymentSystem', false]);
            this.paymentSystemActiveId = paymentSystem.id;
            this.$store.dispatch('webmaster/getPaymentSystem', paymentSystem.id)
                .then(fields => {
                    this.formFields = collect(fields)
                        .map(field => {
                            return {
                                id: field.key,
                                label: field.value,
                                value: '',
                                current_value: field.current_value
                            };
                        })
                        .all();
                });
        },
        addPaymentSystem() {
            this.$store.dispatch('auth/getUser').then(() => {
                if (!this.isCurrentPaymentSystemAlreadyAdded) {
                    this.$store.dispatch('webmaster/addPaymentSystem', {
                            id: this.paymentSystemActiveId,
                            payload: this.preparedFormForRequest
                        })
                        .then(() => {
                            this.$emit('close');
                            this.formFields = [];
                            this.$store.dispatch('webmaster/getPaymentSystems');
                            this.$store.dispatch('auth/getUser');
                        });
                } else {
                    this.selectPaymentSystemTab(this.paymentSystemActive)
                }
            })
        },
        makeTabsSlider() {
            const slider = document.querySelector('.payoutCreate__tabs');
            let isDown = false;
            let startX;
            let scrollLeft;

            slider.addEventListener('mousedown', (e) => {
                isDown = true;
                slider.classList.add('active');
                startX = e.pageX - slider.offsetLeft;
                scrollLeft = slider.scrollLeft;
            });
            slider.addEventListener('mouseleave', () => {
                isDown = false;
                slider.classList.remove('active');
            });
            slider.addEventListener('mouseup', () => {
                isDown = false;
                slider.classList.remove('active');
            });
            slider.addEventListener('mousemove', (e) => {
                if(!isDown) return;
                e.preventDefault();
                const x = e.pageX - slider.offsetLeft;
                const walk = (x - startX) * 3; //scroll-fast
                slider.scrollLeft = scrollLeft - walk;
            });
        },
    },
    mounted() {
        this.selectPaymentSystemTab(this.paymentSystems[0]);
        this.makeTabsSlider();
    }
};
</script>

<style lang="scss">
@import '../../../scss/utils/mixins.scss';
@import '../../../scss/utils/variables.scss';

.payoutCreate {
    max-width: 100%;
    &__tabs {
        position: relative;
        overflow-x: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: nowrap;
        justify-content: flex-start;
        margin-top: 30px;
        margin-bottom: 30px;
        border-bottom: 1px solid $color-day-line;
        z-index: 1;

        transition: all 0.2s;
        will-change: transform;
        user-select: none;
        cursor: pointer;
        @extend .noScrollbar;
        &.active {
            cursor: grabbing;
        }
        &__item {
            position: relative;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: stretch;
            white-space: nowrap;
            padding: 0 30px;
            border-bottom: 2px solid transparent;
            font-weight: 600;
            font-family: $font-primary;
            color: $color-day-lmenu-text;
            cursor: pointer;
            font-size: 14px;
            transition: 100ms color ease;
            @extend .fix-montserrat;
            z-index: 2;
            &:hover {
                color: $color-day-lmenu-active;
            }
            &.active {
                color: $color-day-lmenu-active;
                border-bottom: 2px solid $color-day-light-green;
                cursor: default;
            }
        }
    }

}
</style>